import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
export default {
  name: 'Principal',
  data: function data() {
    return {
      //用户存储的信息
      userInfo: this.Ls.getParseToken('nof_userInfo'),
      //List接口返回表格数据
      tableData: [],
      total: 0,
      //页数总数
      currentPage: 1,
      // 当前页数
      pageSize: 10,
      //每页个数
      //抽屉数据
      drawerState: '',
      drawerData: {
        name: '',
        address: '',
        type: '',
        nature: '',
        region: '',
        remark: '',
        interior: 0,
        //是否内部用户：0不是 1是
        interiorBoolean: false,
        state: 1,
        //客服状态：1启用-1禁用
        stateBoolean: true
      },
      drawer: false,
      direction: 'rtl',
      typeOptions: [{
        id: 1,
        name: '幼儿园'
      }, {
        id: 2,
        name: '培训机构'
      }, {
        id: 3,
        name: '个人'
      }, {
        id: 4,
        name: '其他'
      }],
      natureOptions: [{
        id: 1,
        name: '民办'
      }, {
        id: 2,
        name: '公办'
      }, {
        id: 3,
        name: '企办'
      }, {
        id: 4,
        name: '其他'
      }],
      //搜索关键词
      input: '',
      // 删除弹框显示隐藏
      centerDialogVisible: false,
      roleUserId: '',
      pswUser: ''
    };
  },
  mounted: function mounted() {
    this.initList();
  },
  methods: {
    interiorChange: function interiorChange(val) {
      this.drawerData.interiorBoolean = val;
      if (val) {
        this.drawerData.interior = 1;
      } else {
        this.drawerData.interior = 0;
      }
    },
    stateChange: function stateChange(val) {
      this.drawerData.stateBoolean = val;
      if (val) {
        this.drawerData.state = 1;
      } else {
        this.drawerData.state = -1;
      }
    },
    getName: function getName(id, arr) {
      var nowName;
      for (var i = 0; i < arr.length; i++) {
        if (id === arr[i].id) {
          nowName = arr[i].name;
          break;
        } else {
          nowName = '';
        }
      }
      return nowName;
    },
    // 初始化列表
    initList: function initList() {
      var _this = this;
      var key = '';
      if (this.input) {
        key = this.input;
      } else {
        key = '';
      }
      //
      this.service.axiosUtils({
        requestName: 'getCustomList',
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId,
          keyword: key,
          pageSize: this.pageSize,
          pageIndex: this.currentPage
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this.$toast.success(res.message);
            _this.tableData = res.data.data;
            _this.total = res.data.total;
            _this.tableData.map(function (item) {
              item.typeName = _this.getName(item.type, _this.typeOptions);
              if (!item.typeName) {
                item.typeName = '暂无';
              }
              item.interior === 1 ? item.interiorName = '是' : item.interiorName = '否';
            });
          } else {
            _this.$toast.error(res.message);
          }
        }
      });
    },
    // 修改正常/禁用状态
    editState: function editState(row) {
      var _this2 = this;
      this.service.axiosUtils({
        requestName: 'changeCustomState',
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId,
          id: row.id,
          state: row.state
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this2.$toast.success(res.message);
            if (row.state === 1) row.state = -1;else row.state = 1;
          } else {
            _this2.$toast.error(res.message);
          }
        }
      });
    },
    // 添加按钮点击函数
    addFun: function addFun() {
      this.drawerState = 'add';
      this.drawer = true;
      this.drawerData = {
        name: '',
        address: '',
        type: '',
        nature: '',
        region: '',
        remark: '',
        interior: 0,
        //是否内部用户：0不是 1是
        interiorBoolean: false,
        state: 1,
        //客服状态：1启用-1禁用
        stateBoolean: true
      };
    },
    // 抽屉确定函数
    confirmClick: function confirmClick() {
      var _this3 = this;
      //名判断
      var reg = /^[\u4E00-\u9FA5A-Za-z0-9]+$/;
      if (!reg.test(this.drawerData.name)) {
        this.$toast.error('客户名不能为空或包含特殊字符');
        return;
      }
      // console.log(this.drawerData.interior)
      // if(this.drawerData.interior !== 0 || this.drawerData.interior !== 1){
      //   this.$toast.error('是否内部用户未选择');
      //   return;
      // }
      // if(!this.drawerData.state){
      //   this.$toast.error('客户状态未选择');
      //   return;
      // }

      this.drawerData.userId = this.userInfo.userId;
      this.drawerData.roleId = this.userInfo.roleId;

      //需要判断是"添加"还是"编辑"
      var url = '';
      if (this.drawerState === 'edit') {
        url = 'editCustom';
      } else {
        url = 'addCustom';
      }
      this.service.axiosUtils({
        requestName: url,
        data: this.drawerData,
        sucFn: function sucFn(res) {
          console.log(res);
          if (res.status === 1) {
            _this3.$toast.success(res.message);
            _this3.drawer = false;
            _this3.drawerData = {
              name: '',
              address: '',
              type: '',
              nature: '',
              region: '',
              remark: '',
              interior: '',
              state: ''
            };
            _this3.currentPage = 1;
            _this3.initList();
          } else {
            _this3.$toast.error(res.message);
          }
        }
      });
    },
    // 抽屉取消函数
    cancelClick: function cancelClick() {
      this.drawer = false;
    },
    // 操作函数
    detail: function detail(row, type) {
      console.log(row);
      if (type === 'edit') {
        //编辑
        this.drawerState = 'edit';
        this.drawer = true;
        this.drawerData = {
          id: row.id,
          name: row.name,
          address: row.address,
          type: row.type,
          nature: row.nature,
          region: row.region,
          remark: row.remark,
          interior: row.interior,
          state: row.state
        };
        this.drawerData.typeName = this.getName(row.type, this.typeOptions);
        this.drawerData.natureName = this.getName(row.nature, this.natureOptions);
        this.drawerData.interiorBoolean = this.drawerData.interior === 0 ? false : true;
        this.drawerData.stateBoolean = this.drawerData.state === 1 ? true : false;
        console.log(this.drawerData);
      } else if (type === 'role') {} else if (type === 'del') {
        this.centerDialogVisible = true;
        this.userRoleId = row.userRoleId;
      } else {}
    },
    //删除弹框确定函数
    confirmFun: function confirmFun() {
      var _this4 = this;
      //删除当前的角色
      this.service.axiosUtils({
        requestName: 'delFlag',
        data: {
          userRoleId: this.userRoleId
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this4.$toast.success(res.message);
            _this4.userRoleId = '';
            _this4.centerDialogVisible = false;
            _this4.currentPage = 1;
            _this4.initUserList();
          } else {
            _this4.$toast.error(res.message);
          }
        }
      });
    },
    searchFun: function searchFun() {
      this.initUserList(this.input);
    },
    searchClear: function searchClear() {
      this.input = '';
      this.initUserList();
    },
    // 页码修改pagesize
    handleSizeChange: function handleSizeChange(page) {
      this.pageSize = page;
      this.initList();
    },
    // 页码修改当前页码
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.initList();
    }
  }
};