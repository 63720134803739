import "core-js/modules/es.function.name.js";
import principal from './Principal';
import teacher from './Teacher';
import order from './Order';
import auth from './Auth';
export default {
  name: "Custom",
  components: {
    principal: principal,
    teacher: teacher,
    order: order,
    auth: auth
  },
  data: function data() {
    return {
      infoOrg: this.Ls.getParseToken('nof_orgInfo'),
      leftTab: 1,
      leftData: [{
        title: '人员',
        children: [{
          id: 1,
          name: '成员管理',
          path: '/customer/manage/member'
        }
        // {
        //   id:2,
        //   name:'负责人管理',
        //   path:'/customer/manage/principal'
        // },
        // {
        //   id:3,
        //   name:'教师管理',
        //   path:'/customer/manage/teacher'
        // }
        ]
      }, {
        title: '授权',
        children: [{
          id: 3,
          name: '订单管理',
          path: '/customer/manage/order'
        }, {
          id: 4,
          name: '授权管理',
          path: '/customer/manage/auth'
        }]
      }]
    };
  },
  watch: {},
  methods: {
    tabFun: function tabFun(tab, path) {
      this.leftTab = tab;
      this.$router.push({
        path: path,
        query: {
          id: this.$route.query.id,
          nature: this.$route.query.nature,
          name: this.$route.query.name,
          interior: this.$route.query.interior
        }
      });
    }
  }
};